import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import MetaTags from '../../components/MetaTags.js';
import moment from 'moment';
import { graphql } from 'gatsby'
// import 'moment/locale/en';

class AllArticlesEn extends Component {

    componentDidMount() {
    }

    goodPost(postListTmp) {
        // console.log(postListTmp)
        var edges = []
        postListTmp.forEach(element => {
            if (element.node.lang === "de") edges.push(element)
        });
        return edges
    }

    render() {
        moment.locale('de')
        const postList = this.props.data.allBlogPostUnique;
        postList.edges = this.goodPost(postList.edges)
        var lien_image_share = "https://s3.eu-central-1.amazonaws.com/fragrancebutler.me/share_image/CTA_share/share_image_sdp.png"
        return (
            <Layout>
                <MetaTags
                    title={"Blog Sommelier du Parfum | Tout savoir sur le parfum"}
                    description={"Haben Sie Lust, mehr über die Welt der Parfums zu erfahren? Erfahren Sie mehr über die Parfümerie, ihre Geschichte und die neuesten Nachrichten sowie alle Tipps, wie Sie das Beste aus Ihren Parfüms herausholen können."}
                    thumbnail={lien_image_share}
                    // url={url_post}
                    pathname={this.props.location.href}
                    keywords={[]}
                />
                <div className="sizeContainerListTot" style={{ paddingBottom: 60 }}>
                    <div className="" style={{ width: "100%", padding: "0 20px" }}>
                        {postList.edges.map(({ node }, i) => (
                            <div key={'post-' + i} style={{ fontFamily: 'SB', marginTop: 10, fontSize: 15 }}>
                                <Link to={"/" + node.slug} style={{ textDecoration: "none", color: "#000" }} className="link" >
                                    — {node.title} <span style={{ fontFamily: "FuturaLight", fontSize: 14, color: "#555" }}>({moment(node.date).format("MMMM Do YYYY")})</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default AllArticlesEn


export const listQuerysDe = graphql`
    query listQuerysDe {
        allBlogPostUnique(sort: { order: DESC, fields: date }) {
        edges {
            node {
                date
                title
                description
                tags
                reading
                slug
                lang
                author
                image_header
            }
        }

      group(field: tags) {
        fieldValue
        totalCount
      }
    }
}
`
